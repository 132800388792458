import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_shiftSet = _resolveComponent("shiftSet");

  const _component_a_tab_pane = _resolveComponent("a-tab-pane");

  const _component_attendanceGroupSet = _resolveComponent("attendanceGroupSet");

  const _component_typeset = _resolveComponent("typeset");

  const _component_a_tabs = _resolveComponent("a-tabs");

  return _openBlock(), _createBlock(_component_a_tabs, {
    type: "card",
    activeKey: _ctx.activeKey,
    onChange: _ctx.handleTabChange
  }, {
    default: _withCtx(() => [_createVNode(_component_a_tab_pane, {
      key: "1",
      tab: "班次设置"
    }, {
      default: _withCtx(() => [_createVNode(_component_shiftSet)]),
      _: 1
    }), _createVNode(_component_a_tab_pane, {
      key: "2",
      tab: "考勤组设置"
    }, {
      default: _withCtx(() => [_createVNode(_component_attendanceGroupSet)]),
      _: 1
    }), _createVNode(_component_a_tab_pane, {
      key: "3",
      tab: "排班"
    }, {
      default: _withCtx(() => [_createVNode(_component_typeset, {
        ref: "tabPane"
      }, null, 512)]),
      _: 1
    })]),
    _: 1
  }, 8, ["activeKey", "onChange"]);
}