/*
 * 考勤考核接口列表
 */

import axios from '@/utils/http'; // 导入http中创建的axios实例
import instance from '@/utils/http';

/**
 * 当日考勤
 */
export const selectClockInRecordByPagination = (param) => {
  return axios.post(`/wlapi/clockin/selectClockInRecordByPagination`, param);
}

/**
 * 获取考勤列表-日
 */
export const selectCheckindayByPagination = (param) => {
  return axios.post(`/wlapi/checkinday/selectCheckindayByPagination`, param);
}

/**
 * 获取考勤列表-月
 */
export const selectCheckinmonthByPagination = (param) => {
  return axios.post(`/wlapi/checkinmonth/selectCheckinmonthByPagination`, param);
}

/**
 * 获取考勤列表-年
 */
export const selectCheckinyearByPagination = (param) => {
  return axios.post(`/wlapi/checkinyear/selectCheckinyearByPagination`, param);
}

/**
 * 添加迟到早退原因
 */
export const insertCheckinReason = (param) => {
  return axios.post(`/wlapi/checkinday/insertCheckinReason`, param);
}

/**
 * 人工修改考勤数据
 */
export const updateHyCheckdayByState = (param) => {
  return axios.post(`/wlapi/checkinday/updateHyCheckdayByState`, param);
}

/**
 * 考勤数据确认正常
 */
export const updateState = (param) => {
  return axios.post(`/wlapi/checkinday/updateState`, param);
}

/**
 * 获取考核列表-月
 */
export const selectExamineMonthlist = (param) => {
  return axios.post(`/api/HyExamineRecord/selectExamineMonthlist`, param);
}

/**
 * 获取考核列表-年
 */
export const selectExamineYearlist = (param) => {
  return axios.post(`/api/HyExamineRecord/selectExamineYearlist`, param);
}

/**
 * 获取考核列表-个人
 */
export const selectExaminePersonlist = (param) => {
  return axios.post(`/api/HyExamineRecord/selectExaminePersonlist`, param);
}

/**
 * 获取奖惩详情
 */
export const selectExamineRecordListByParams = (param) => {
  return axios.post(`/api/HyExamineRecord/selectExamineRecordListByParams`, param);
}

/**
 * 添加奖惩事项
 */
export const insertClockInRecord = (param) => {
  return axios.post(`/api/HyExamineRecord/insertExamineRecord`, param);
}

/**
 * 删除奖惩事项
 */
export const deleteExamineRecordByUserNo = (param) => {
  return axios.post(`/api/HyExamineRecord/deleteExamineRecordByUserNo`, param);
}

/**
 * 获取工资表数据
 */
export const selectHySalaryByParam = (param) => {
  return axios.post(`/api/HySalary/selectHySalaryByParam`, param);
}


/**
 * 获取考勤报表下载数据
 */
export const selectClockInRecordAll = (param) => {
  return axios.post(`/api/clockin/selectClockInRecordAll`, param);
}
/**
 * 获取工作时间设置数据
 */
export const selectKnTimeSettings = (param) => {
  return axios.post(`/wlapi/knTimeSettings/selectKnTimeSettings`, param);
}
/**
 * 修改工作时间
 */
export const updateKnTimeSettings = (param) => {
  return axios.post(`/wlapi/knTimeSettings/updateKnTimeSettings`, param);
}
/**
 * 查询当月考核规则
 */
 export const selectCheckAndLateRule = (param) => {
  return axios.post(`/personnelapi/knCheckRule/selectCheckAndLateRule`, param);
}
/**
 * 统计现场人数
 */
 export const selectPeopleNumByDayAndBelongProject = (param) => {
  return axios.post(`/wlapi/clockin/selectPeopleNumByDayAndBelongProject`, param);
}
/**
 * 重置现场人数
 */
 export const updateSiteNum = (param) => {
  return axios.post(`/wlapi/clockin/updateSiteNum`, param);
}
/**
 * 获取背景图片
 */
 export const getBackgroundImg = (param) => {
  return axios.post(`/wlapi/clockin/getBackgroundImg`, param);
}
/**
 * 上传背景图片
 */
 export const uploadBackgroundImg = (param) => {
  return axios.post(`/wlapi/clockin/uploadBackgroundImg`, param);
}

/**
 * 班次查询
 */
 export const selectClassOrderList = (param) => {
  return axios.post(`/wlapi/knClassOrder/selectClassOrderList`, param);
}
/**
 * 新建班次
 */
 export const insertClassOrder = (param) => {
  return axios.post(`/wlapi/knClassOrder/insertClassOrder`, param);
}
/**
 * 班次详情
 */
 export const selectClassOrderById = (param) => {
  return axios.get(`/wlapi/knClassOrder/selectClassOrderById?id=${param}`);
}
/**
 * 班次编辑
 */
 export const updateClassOrder = (param) => {
  return axios.post(`/wlapi/knClassOrder/updateClassOrder`, param);
}
/**
 *  修改班次是否生效接口
 */
 export const updateClassOrderOfIsEffect = (param) => {
  return axios.post(`/wlapi/knClassOrder/updateClassOrderOfIsEffect`, param);
}
/**
 * 考勤组查询
 */
 export const selectAttendanceGroup = (param) => {
  return axios.post(`/wlapi/knAttendanceGroup/selectAttendanceGroup`, param);
}
/**
 * 新增考勤组
 */
 export const insertAttendanceGroup = (param) => {
  return axios.post(`/wlapi/knAttendanceGroup/insertAttendanceGroup`, param);
}
/**
 * 考勤组详情
 */
 export const selectAttendanceGroupById = (param) => {
  return axios.get(`/wlapi/knAttendanceGroup/selectAttendanceGroupById?id=${param}`);
}
/**
 * 考勤组排班
 */
 export const selectSchedulingByGroupId = (param) => {
  return axios.post(`/wlapi/knScheduling/selectSchedulingByGroupId`, param);
}
/**
 * 考勤组编辑
 */
 export const updateAttendanceGroup = (param) => {
  return axios.post(`/wlapi/knAttendanceGroup/updateAttendanceGroup`, param);
}
/**
 * 修改考勤组是否生效接口
 */
 export const updateAttendanceGroupOfIsEffect = (param) => {
  return axios.post(`/wlapi/knAttendanceGroup/updateAttendanceGroupOfIsEffect`, param);
}
/**
 * 查询项目下未分组的用户
 */
 export const selectNoGroupEmployee = (param) => {
  return axios.post(`/wlapi/knAttendanceGroup/selectNoGroupEmployee`, param);
}
/**
 * 查询考勤组下的所有员工
 */
 export const selectEmployee = (param) => {
  return axios.post(`/wlapi/knAttendanceGroup/selectEmployee`, param);
}
/**
 * 查询排班周期接口
 */
 export const selectSchedulePeriod = (param) => {
  return axios.post(`/wlapi/knSchedulePeriod/selectSchedulePeriod`, param);
}
/**
 * 查询班次
 */
 export const selectClassOrders = (param) => {
  return axios.post(`/wlapi/knClassOrder/selectClassOrders`, param);
}
/**
 * 新增排班周期接口
 */
 export const insertSchedulePeriod = (param) => {
  return axios.post(`/wlapi/knSchedulePeriod/insertSchedulePeriod`, param);
}
/**
 * 编辑排班周期接口
 */
 export const updateSchedulePeriod = (param) => {
  return axios.post(`/wlapi/knSchedulePeriod/updateSchedulePeriod`, param);
}
/**
 * 查询排班下排班接口
 */
 export const selectSchedulingList = (param) => {
  return axios.post(`/wlapi/knScheduling/selectSchedulingList`, param);
}
/**
 * 设置排班
 */
 export const insertOrUpdateScheduling = (param) => {
  return axios.post(`/wlapi/knScheduling/insertOrUpdateScheduling`, param);
}
/**
 * 我的排班
 */
 export const selectSchedulingByUid = (param) => {
  return axios.post(`/wlapi/knScheduling/selectSchedulingByUid`, param);
}
/**
 * 查询排班周期（用于排班设置）
 */
 export const selectSchedulePeriodByBelongProject = (param) => {
  return axios.post(`/wlapi/knSchedulePeriod/selectSchedulePeriodByBelongProject`, param);
}
/**
 * 新增打卡记录
 */
 export const insertClockInRecord1 = (param) => {
  return axios.post(`/wlapi/clockin/insertClockInRecord`, param);
}

/**
 * 删除打卡信息
 */
 export const deleteHyClockIn = (param) => {
  return axios.post(`/wlapi/clockin/deleteHyClockIn`, param);
}

/**
 * 查询第一次考勤的时间
 */
 export const selectFirstTimeByCheckinDay = (param) => {
  return axios.post(`/wlapi/checkinday/selectFirstTimeByCheckinDay`, param);
}

/**
 * 查询部门下是否存在员工信息
 */
 export const selectEmployeeByDepartmentId = (param) => {
  return axios.post(`/wlapi/checkinday/selectEmployeeByDepartmentId`, param);
}

/**
 * 查询考勤报表
 */
 export const selectCheckinDayDtoByParam = (param) => {
  return axios.post(`/wlapi/checkinday/selectCheckinDayDtoByParam`, param);
}