import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, onMounted, reactive, computed, nextTick } from 'vue';
import { selectAttendanceGroup, selectAttendanceGroupById, updateAttendanceGroupOfIsEffect, selectNoGroupEmployee, selectEmployee, insertAttendanceGroup, updateAttendanceGroup, selectSchedulingByGroupId } from '@/api/attendance';
import moment from 'moment';
import { useStore } from 'vuex'; //表头

const columns = [{
  title: '考勤组名称',
  dataIndex: 'groupName'
}, {
  title: '人数',
  dataIndex: 'groupNum'
}, {
  title: '最后更新时间',
  dataIndex: 'updateTime'
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
const groupColumns = [{
  title: '姓名',
  dataIndex: 'title'
}, {
  title: '工号',
  dataIndex: 'workNo'
}, {
  title: '部门',
  dataIndex: 'departmentName'
}, {
  title: '岗位',
  dataIndex: 'post'
}];
const leftTableColumns = [{
  dataIndex: 'title',
  title: '姓名'
}, {
  title: '工号',
  dataIndex: 'workNo'
}, {
  title: '部门',
  dataIndex: 'departmentName'
}, {
  dataIndex: 'post',
  title: '岗位'
}];
const rightTableColumns = [{
  dataIndex: 'title',
  title: '姓名'
}, {
  title: '工号',
  dataIndex: 'workNo'
}, {
  title: '部门',
  dataIndex: 'departmentName'
}, {
  dataIndex: 'post',
  title: '岗位'
}];
export default defineComponent({
  components: {},

  setup() {
    //表格
    const tableData = ref([]);
    const loading = ref(false);

    const getTableData = async () => {
      loading.value = true;

      try {
        let res = await selectAttendanceGroup();

        if (res.code === 200) {
          const arr = [];
          tableData.value = [];
          if (res.data) res.data.forEach(item => {
            arr.push({ ...item,
              key: item.id
            });
          });
          Object.assign(tableData.value, arr);
          loading.value = false;
        } else {
          loading.value = false;

          _message.error(res.message);
        }
      } catch {
        loading.value = false;
      }
    }; //新建考勤组


    const newSetVisible = ref(false);
    const newSetTitle = ref('新建考勤组');
    const isDisabledForCl = ref(false);

    const handleNew = (key, num) => {
      if (num === 0) {
        newSetTitle.value = '新建考勤组';
        isDisabledForCl.value = false;
        formState.id = null;
        formState.groupName = null;
        formState.oldUid = null;
        formState.newUid = null;
        formState.isEffect = 0;
        getPeople();
      } else if (num === 1) {
        newSetTitle.value = '考勤组详情';
        isDisabledForCl.value = true;
        formState.id = key;
        getTableHeader(groupMonthValue.value);
        toViewPeople(key);
        getTypographyData(key);
      } else {
        newSetTitle.value = '编辑考勤组';
        isDisabledForCl.value = false;
        formState.id = key;
        toViewPeople(key);
      }
    };

    const formRef = ref(); //是否立即生效

    const isShowEffect = ref(false);

    const setEffect = async () => {
      const param = {
        groupId: formState.id,
        isEffect: formState.isEffect
      };
      const res = await updateAttendanceGroupOfIsEffect(param);

      if (res.code === 200) {
        isShowEffect.value = false;
        newSetVisible.value = false;
        getTableData();
      } else {
        _message.error(res.message);
      }
    }; //新建


    const peopleVisible = ref(false);
    const leftData = ref([]);
    const rigthData = ref([]);
    const mockData = ref([]);
    const originTargetKeys = ref([]); // const groupNo = ref('')

    const getPeople = () => {
      Promise.all([getLeftData()]).then(res => {
        console.log('Promise.all', res);

        try {
          leftData.value = [];
          rigthData.value = [];
          res[0].forEach(item => {
            leftData.value.push({
              key: item.uid,
              title: item.userName,
              post: item.jobName,
              workNo: item.workNo,
              departmentName: item.departmentName
            });
          });
          mockData.value = leftData.value.concat(rigthData.value);
          originTargetKeys.value = rigthData.value.map(item => item.key);
          nextTick(() => {
            newSetVisible.value = true; // groupNo.value = key
          });
        } catch (error) {
          console.log(error);
        }
      });
    };

    const toViewPeople = key => {
      Promise.all([getLeftData(), getDetaiData(key)]).then(res => {
        console.log('Promise.all1', res);

        try {
          leftData.value = [];
          rigthData.value = [];
          res[0].forEach(item => {
            leftData.value.push({
              key: item.uid,
              title: item.userName,
              post: item.jobName,
              workNo: item.workNo,
              departmentName: item.departmentName
            });
          });
          let oldId = [];
          res[1].employeeList.forEach(item => {
            oldId.push(item.uid);
            rigthData.value.push({
              key: item.uid,
              title: item.userName,
              post: item.jobName,
              workNo: item.workNo,
              departmentName: item.departmentName
            });
          });
          mockData.value = leftData.value.concat(rigthData.value);
          originTargetKeys.value = rigthData.value.map(item => item.key);
          formState.groupName = res[1].groupName;
          formState.oldUid = oldId.toString();
          formState.newUid = oldId.toString();
          nextTick(() => {
            newSetVisible.value = true; // groupNo.value = key
          });
        } catch (error) {
          console.log(error);
        }
      });
    };

    const targetKeys = ref(originTargetKeys);
    const leftColumns = ref(leftTableColumns);
    const rightColumns = ref(rightTableColumns);

    const onChange = async (nextTargetKeys, direction, moveKeys) => {
      targetKeys.value = nextTargetKeys;
      console.log('nextTargetKeys', nextTargetKeys, direction, moveKeys);
      formState.newUid = nextTargetKeys.join(',');
      console.log(formState.newUid);
    };

    const getRowSelection = ({
      disabled,
      selectedKeys,
      onItemSelectAll,
      onItemSelect
    }) => {
      return {
        getCheckboxProps: item => ({
          disabled: disabled || item.disabled
        }),

        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows.filter(item => !item.disabled).map(({
            key
          }) => key);
          const diffKeys = selected ? difference(treeSelectedKeys, selectedKeys) : difference(selectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
        },

        onSelect({
          key
        }, selected) {
          onItemSelect(key, selected);
        },

        selectedRowKeys: selectedKeys
      };
    }; //左边数据


    const getLeftData = () => {
      return new Promise((resolve, reject) => {
        selectNoGroupEmployee().then(res => {
          resolve(res.data);
        });
      }).catch(error => {
        console.log(error);
        reject(error.data);
      });
    }; //右边数据
    // const getRigthData = (key) => {
    //   return new Promise((resolve, reject) => {
    //     selectEmployee({grouId:key}).then(res=> {
    //       resolve(res.data)
    //     })
    //    }).catch((error) => {
    //     console.log(error)
    //     reject(error.data)
    //   })
    // }


    const formState = reactive({
      id: null,
      groupName: '',
      oldUid: null,
      newUid: null,
      isEffect: 0
    });
    const rules = {
      groupName: [{
        required: true,
        message: '考勤组名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }]
    }; //获取详情数据

    const getDetaiData = id => {
      return new Promise((resolve, reject) => {
        selectAttendanceGroupById(id).then(res => {
          resolve(res.data);
        });
      }).catch(error => {
        console.log(error);
        reject(error.data);
      });
    };

    const onSubmit = () => {
      console.log('values', formState);
      formRef.value.validate().then(async () => {
        loading.value = true; // console.log(11111111)

        let obj = {};
        Object.assign(obj, formState); // console.log(obj)

        let res = await insertAttendanceGroup(obj);

        if (res.code === 200) {
          _message.success(res.message);

          newSetVisible.value = false;
          getTableData();
        } else {
          _message.error(res.message);

          loading.value = false;
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    };

    const onEditor = () => {
      console.log('values', formState);
      formRef.value.validate().then(async () => {
        loading.value = true; // console.log(11111111)

        let obj = {};
        Object.assign(obj, formState); // console.log(obj)

        let res = await updateAttendanceGroup(obj);

        if (res.code === 200) {
          _message.success(res.message);

          if (res.data) {
            isShowEffect.value = true;
          } else {
            newSetVisible.value = false;
            getTableData();
          }
        } else {
          _message.error(res.message);

          loading.value = false;
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    };

    const groupMonthValue = ref(moment().startOf('day').format('YYYY-MM'));

    const handleMonth = data => {
      getTableHeader(data);
      getTypographyData(formState.id);
    };

    let dayNum = ref(30);
    const typographyColumns = ref([]);

    const getTableHeader = month => {
      typographyColumns.value = [];
      let arr = month.split('-');

      switch (arr[1]) {
        case '01':
        case '03':
        case '05':
        case '07':
        case '08':
        case '10':
        case '12':
          dayNum.value = 31;
          break;

        case '04':
        case '06':
        case '09':
        case '11':
          dayNum.value = 30;
          break;

        case '02':
          dayNum.value = 28;
          break;
      }

      let weekObj = {
        1: '一',
        2: '二',
        3: '三',
        4: '四',
        5: '五',
        6: '六',
        7: '日'
      };

      for (let i = 1; i <= dayNum.value; i++) {
        let str = weekObj[moment(`${month}-${i}`).isoWeekday()];
        typographyColumns.value.push({
          title: `${i}${str}`,
          dataIndex: i,
          slots: {
            customRender: i
          },
          width: '50px'
        });
      } // return columns

    };

    const typographyData = ref([]);
    const typographyLoading = ref(false);

    const getTypographyData = async id => {
      const param = {
        groupId: id,
        scheduleTime: groupMonthValue.value
      };
      typographyLoading.value = true;
      let res = await selectSchedulingByGroupId(param);

      if (res.code === 200) {
        const arr = [];
        typographyData.value = [];

        if (res.data) {
          let schedulePlan = JSON.parse(res.data.schedulePlan);
          let obj = {};
          schedulePlan.forEach((item, index) => {
            obj[index + 1] = { ...item
            };
          });
          arr.push(obj);
        }

        console.log(arr);
        Object.assign(typographyData.value, arr);
      } else {
        _message.error(res.message);

        typographyLoading.value = false;
      }

      typographyLoading.value = false;
    };

    const store = useStore();
    const isCompanyAdmin = computed(() => store.state.user.isCompanyAdmin);
    onMounted(() => {
      getTableData();
    });
    return {
      columns,
      tableData,
      loading,
      newSetVisible,
      handleNew,
      formRef,
      formState,
      rules,
      labelCol: {
        span: 3
      },
      wrapperCol: {
        span: 14
      },
      newSetTitle,
      isShowEffect,
      setEffect,
      mockData,
      targetKeys,
      onChange,
      getRowSelection,
      leftColumns,
      rightColumns,
      isDisabledForCl,
      onSubmit,
      onEditor,
      groupColumns,
      rigthData,
      groupMonthValue,
      handleMonth,
      typographyColumns,
      dayNum,
      typographyData,
      typographyLoading,
      isCompanyAdmin
    };
  }

});