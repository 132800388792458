import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, onMounted, reactive, computed } from 'vue';
import { selectClassOrderList, insertClassOrder, updateClassOrder, updateClassOrderOfIsEffect, selectClassOrderById } from '@/api/attendance';
import { useStore } from 'vuex'; //表头

const columns = [{
  title: '班次名称',
  dataIndex: 'classOrderName'
}, {
  title: '考勤时间',
  dataIndex: 'checkTime'
}, {
  title: '最后更新时间',
  dataIndex: 'updateTime'
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
export default defineComponent({
  components: {},

  setup() {
    //表格
    const tableData = ref([]);
    const loading = ref(false);

    const getTableData = async () => {
      loading.value = true;

      try {
        let res = await selectClassOrderList();

        if (res.code === 200) {
          const arr = [];
          tableData.value = [];
          if (res.data) res.data.forEach(item => {
            arr.push({ ...item,
              key: item.id
            });
          });
          Object.assign(tableData.value, arr);
          console.log(tableData.value);
          loading.value = false;
        } else {
          loading.value = false;

          _message.error(res.message);
        }
      } catch {
        loading.value = false;
      }
    }; //新建班次


    const newSetVisible = ref(false);

    const handleNew = (key, num) => {
      newSetVisible.value = true;

      if (num === 0) {
        formRef.value.resetFields();
        formState.isRequiredRest = false;
        newSetTitle.value = '新建班次';
        isDisabledForCl.value = false;
      } else if (num === 1) {
        newSetTitle.value = '班次详情';
        isDisabledForCl.value = true;
        formState.classId = key;
        selectKnTimeSetting(key);
      } else {
        newSetTitle.value = '编辑班次';
        isDisabledForCl.value = false;
        formState.classId = key;
        selectKnTimeSetting(key);
      }
    };

    const formRef = ref();
    const formState = reactive({
      classOrderName: "",
      workTimeFirst: null,
      workTimeSecond: null,
      workTimeThird: null,
      timesNum: 1,
      //上下班次数
      workingTimeFirst: null,
      //第一次上班时间
      lateOutLateFirst: null,
      //晚到超时分钟数迟到
      halfOutLateFirst: null,
      //晚到超时分钟数半天
      clockEarlyLateFirst: null,
      //最早可提前分钟数打卡
      closingTimeFirst: null,
      //第一次下班时间
      earlyOutEarlyFirst: null,
      //早走超时分钟数早退
      halfOutEarlyFirst: null,
      //早走超时分钟数半天
      clockLateLateFirst: null,
      //最晚可延后分钟数打卡
      restStartTime: null,
      //中途休息时间开始时间
      restEndTime: null,
      //中途休息时间结束时间
      workingTimeSecond: null,
      //第二次上班时间
      lateOutLateSecond: null,
      //早走超时分钟数早退
      halfOutLateSecond: null,
      //早走超时分钟数半天
      clockEarlyLateSecond: null,
      //最晚可延后分钟数打卡
      closingTimeSecond: null,
      //第二次下班时间
      earlyOutEarlySecond: null,
      //早走超时分钟数早退
      halfOutEarlySecond: null,
      //早走超时分钟数半天
      clockLateLateSecond: null,
      //最晚可延后分钟数打卡
      workingTimeThird: null,
      //第三次上班时间
      lateOutLateThird: null,
      //早走超时分钟数早退
      halfOutLateThird: null,
      //早走超时分钟数半天
      clockEarlyLateThird: null,
      //最晚可延后分钟数打卡
      closingTimeThird: null,
      //第三次下班时间
      earlyOutEarlyThird: null,
      //早走超时分钟数早退
      halfOutEarlyThird: null,
      //早走超时分钟数半天
      clockLateLateThird: null,
      //最晚可延后分钟数打卡
      isRequiredRest: false,
      classId: null,
      isEffect: 0
    });
    const rules = {
      classOrderName: [{
        required: true,
        message: '班次名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      workShift: [{
        required: true,
        message: '上班时间不能为空',
        trigger: 'change' // trigger: 'change',

      }],
      lateOutLateFirst: [{
        required: true,
        message: '不能为空',
        trigger: 'blur',
        type: 'number'
      }],
      halfOutLateFirst: [{
        required: true,
        message: '不能为空',
        trigger: 'blur',
        type: 'number'
      }],
      clockEarlyLateFirst: [{
        required: true,
        message: '不能为空',
        trigger: 'blur',
        type: 'number'
      }],
      earlyOutEarlyFirst: [{
        required: true,
        message: '不能为空',
        trigger: 'blur',
        type: 'number'
      }],
      halfOutEarlyFirst: [{
        required: true,
        message: '不能为空',
        trigger: 'blur',
        type: 'number'
      }],
      clockLateLateFirst: [{
        required: true,
        message: '不能为空',
        trigger: 'blur',
        type: 'number'
      }],
      lateOutLateSecond: [{
        required: true,
        message: '不能为空',
        trigger: 'blur',
        type: 'number'
      }],
      halfOutLateSecond: [{
        required: true,
        message: '不能为空',
        trigger: 'blur',
        type: 'number'
      }],
      clockEarlyLateSecond: [{
        required: true,
        message: '不能为空',
        trigger: 'blur',
        type: 'number'
      }],
      earlyOutEarlySecond: [{
        required: true,
        message: '不能为空',
        trigger: 'blur',
        type: 'number'
      }],
      halfOutEarlySecond: [{
        required: true,
        message: '不能为空',
        trigger: 'blur',
        type: 'number'
      }],
      clockLateLateSecond: [{
        required: true,
        message: '不能为空',
        trigger: 'blur',
        type: 'number'
      }],
      lateOutLateThird: [{
        required: true,
        message: '不能为空',
        trigger: 'blur',
        type: 'number'
      }],
      halfOutLateThird: [{
        required: true,
        message: '不能为空',
        trigger: 'blur',
        type: 'number'
      }],
      clockEarlyLateThird: [{
        required: true,
        message: '不能为空',
        trigger: 'blur',
        type: 'number'
      }],
      earlyOutEarlyThird: [{
        required: true,
        message: '不能为空',
        trigger: 'blur',
        type: 'number'
      }],
      halfOutEarlyThird: [{
        required: true,
        message: '不能为空',
        trigger: 'blur',
        type: 'number'
      }],
      clockLateLateThird: [{
        required: true,
        message: '不能为空',
        trigger: 'blur',
        type: 'number'
      }]
    };

    const changeRadioNumber = val => {
      formState.workingTimeFirst = null;
      formState.lateOutLateFirst = null;
      formState.halfOutLateFirst = null;
      formState.clockEarlyLateFirst = null;
      formState.closingTimeFirst = null;
      formState.earlyOutEarlyFirst = null;
      formState.halfOutEarlyFirst = null;
      formState.clockLateLateFirst = null;
      formState.restStartTime = null;
      formState.restEndTime = null;
      formState.workingTimeSecond = null;
      formState.lateOutLateSecond = null;
      formState.halfOutLateSecond = null;
      formState.clockEarlyLateSecond = null;
      formState.closingTimeSecond = null;
      formState.earlyOutEarlySecond = null;
      formState.halfOutEarlySecond = null;
      formState.clockLateLateSecond = null;
      formState.workingTimeThird = null;
      formState.lateOutLateThird = null;
      formState.halfOutLateThird = null;
      formState.clockEarlyLateThird = null;
      formState.closingTimeThird = null;
      formState.earlyOutEarlyThird = null;
      formState.halfOutEarlyThird = null;
      formState.clockLateLateThird = null;
      formState.isRequiredRest = null;
    };

    const workTimeFirst = computed(() => {
      let time = time_to_sec(formState.closingTimeFirst) - time_to_sec(formState.workingTimeFirst) - (time_to_sec(formState.restEndTime) - time_to_sec(formState.restStartTime)); //  console.log(1111,time)

      return (Math.floor(time / 60).toString().length < 2 ? "0" + Math.floor(time / 60).toString() : Math.floor(time / 60).toString()) + "小时" + ((time % 60).toString().length < 2 ? "0" + (time % 60).toString() : (time % 60).toString()) + "分钟";
    });
    const workTimeSecond = computed(() => {
      let time = time_to_sec(formState.closingTimeFirst) - time_to_sec(formState.workingTimeFirst) + (time_to_sec(formState.closingTimeSecond) - time_to_sec(formState.workingTimeSecond)); //  console.log(2222,time)

      return (Math.floor(time / 60).toString().length < 2 ? "0" + Math.floor(time / 60).toString() : Math.floor(time / 60).toString()) + "小时" + ((time % 60).toString().length < 2 ? "0" + (time % 60).toString() : (time % 60).toString()) + "分钟";
    });
    const workTimeThird = computed(() => {
      let time = time_to_sec(formState.closingTimeFirst) - time_to_sec(formState.workingTimeFirst) + (time_to_sec(formState.closingTimeSecond) - time_to_sec(formState.workingTimeSecond)) + (time_to_sec(formState.closingTimeThird) - time_to_sec(formState.workingTimeThird));
      return (Math.floor(time / 60).toString().length < 2 ? "0" + Math.floor(time / 60).toString() : Math.floor(time / 60).toString()) + "小时" + ((time % 60).toString().length < 2 ? "0" + (time % 60).toString() : (time % 60).toString()) + "分钟";
    });

    const numberEcho = (val, name, num, a, b) => {
      if (b === undefined) {
        if (!val) {
          formState[name] = num;
        }
      } else {
        if (!val) {
          formState.knLateRules[name][a] = num;
        }
      }
    }; //时间转成分钟


    const time_to_sec = time => {
      // console.log(time)
      if (time !== null) {
        var s = "";
        var hour = time.split(":")[0];
        var min = time.split(":")[1];
        s = Number(hour * 60) + Number(min);
        return s;
      } else {
        return 0;
      }
    };

    const changeCheckbox = e => {
      //  console.log(1111,e)
      if (!e.target.checked) {
        formState.restStartTime = null;
        formState.restEndTime = null;
      }
    };

    const onSubmit = () => {
      console.log('values', formState);
      formRef.value.validate().then(async () => {
        if (new Date(`2022-02-24 ${formState.closingTimeFirst}`) <= new Date(`2022-02-24 ${formState.workingTimeFirst}`)) {
          _message.error('第一次上班时间不能大于或等于第一次下班时间');

          return;
        }

        if (formState.timesNum === 1 && formState.isRequiredRest) {
          if (new Date(`2022-02-24 ${formState.restStartTime}`) <= new Date(`2022-02-24 ${formState.workingTimeFirst}`)) {
            _message.error('第一次中途休息时间开始时间不能小于或等于第一次上班时间');

            return;
          }

          if (new Date(`2022-02-24 ${formState.restEndTime}`) >= new Date(`2022-02-24 ${formState.closingTimeFirst}`)) {
            _message.error('第一次中途休息时间结束时间不能大于或等于第一次下班时间');

            return;
          }
        }

        if (new Date(`2022-02-24 ${formState.closingTimeFirst}`) >= new Date(`2022-02-24 ${formState.workingTimeSecond}`) && formState.timesNum !== 1) {
          _message.error('第二次上班时间不能大于或等于第一次下班时间');

          return;
        }

        if (new Date(`2022-02-24 ${formState.workingTimeSecond}`) >= new Date(`2022-02-24 ${formState.closingTimeSecond}`) && formState.timesNum !== 1) {
          _message.error('第二次上班时间不能大于或等于第二次下班时间');

          return;
        }

        if (new Date(`2022-02-24 ${formState.closingTimeSecond}`) >= new Date(`2022-02-24 ${formState.workingTimeThird}`) && formState.timesNum === 3) {
          _message.error('第二次下班时间不能大于或等于第三次上班时间');

          return;
        }

        if (new Date(`2022-02-24 ${formState.workingTimeThird}`) >= new Date(`2022-02-24 ${formState.closingTimeThird}`) && formState.timesNum === 3) {
          _message.error('第三次上班时间不能大于或等于第三次下班时间');

          return;
        }

        loading.value = true; // console.log(11111111)

        let obj = {};
        Object.assign(obj, formState); // console.log(obj)

        let res = await insertClassOrder(obj);

        if (res.code === 200) {
          _message.success(res.message);

          newSetVisible.value = false;
          getTableData();
        } else {
          _message.error(res.message);

          loading.value = false;
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    };

    const isDisabledForCl = ref(false);
    const newSetTitle = ref('新建班次');

    const onEditor = () => {
      // console.log('values', formState);
      formRef.value.validate().then(async () => {
        if (new Date(`2022-02-24 ${formState.closingTimeFirst}`) <= new Date(`2022-02-24 ${formState.workingTimeFirst}`)) {
          _message.error('第一次上班时间不能大于或等于第一次下班时间');

          return;
        }

        if (formState.timesNum === 1 && formState.isRequiredRest) {
          if (new Date(`2022-02-24 ${formState.restStartTime}`) <= new Date(`2022-02-24 ${formState.workingTimeFirst}`)) {
            _message.error('第一次中途休息时间开始时间不能小于或等于第一次上班时间');

            return;
          }

          if (new Date(`2022-02-24 ${formState.restEndTime}`) >= new Date(`2022-02-24 ${formState.closingTimeFirst}`)) {
            _message.error('第一次中途休息时间结束时间不能大于或等于第一次下班时间');

            return;
          }
        }

        if (new Date(`2022-02-24 ${formState.closingTimeFirst}`) >= new Date(`2022-02-24 ${formState.workingTimeSecond}`) && formState.timesNum !== 1) {
          _message.error('第二次上班时间不能大于或等于第一次下班时间');

          return;
        }

        if (new Date(`2022-02-24 ${formState.workingTimeSecond}`) >= new Date(`2022-02-24 ${formState.closingTimeSecond}`) && formState.timesNum !== 1) {
          _message.error('第二次上班时间不能大于或等于第二次下班时间');

          return;
        }

        if (new Date(`2022-02-24 ${formState.closingTimeSecond}`) >= new Date(`2022-02-24 ${formState.workingTimeThird}`) && formState.timesNum === 3) {
          _message.error('第二次下班时间不能大于或等于第三次上班时间');

          return;
        }

        if (new Date(`2022-02-24 ${formState.workingTimeThird}`) >= new Date(`2022-02-24 ${formState.closingTimeThird}`) && formState.timesNum === 3) {
          _message.error('第三次上班时间不能大于或等于第三次下班时间');

          return;
        }

        loading.value = true; // console.log(11111111)

        let obj = {};
        Object.assign(obj, formState); // console.log(obj)

        let res = await updateClassOrder(obj);

        if (res.code === 200) {
          _message.success(res.message);

          if (res.data) {
            isShowEffect.value = true;
          } else {
            newSetVisible.value = false;
            getTableData();
          }
        } else {
          _message.error(res.message);

          loading.value = false;
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    }; //是否立即生效


    const isShowEffect = ref(false);

    const setEffect = async () => {
      const param = {
        classId: formState.classId,
        isEffect: formState.isEffect
      };
      const res = await updateClassOrderOfIsEffect(param);

      if (res.code === 200) {
        isShowEffect.value = false;
        newSetVisible.value = false;
        getTableData();
      } else {
        _message.error(res.message);
      }
    }; //详情


    const selectKnTimeSetting = async id => {
      let res = await selectClassOrderById(id);

      if (res.code === 200) {
        Object.assign(formState, res.data);

        if (formState.restStartTime && formState.restEndTime) {
          formState.isRequiredRest = true;
        } // console.log('wwwwwwww',formState.addMustDate)

      } else {
        _message.error(res.message);
      }
    };

    const store = useStore();
    const isCompanyAdmin = computed(() => store.state.user.isCompanyAdmin);
    onMounted(() => {
      getTableData();
    });
    return {
      columns,
      tableData,
      loading,
      newSetVisible,
      handleNew,
      formRef,
      formState,
      changeRadioNumber,
      rules,
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      },
      workTimeFirst,
      workTimeSecond,
      workTimeThird,
      numberEcho,
      changeCheckbox,
      onSubmit,
      isDisabledForCl,
      newSetTitle,
      onEditor,
      isShowEffect,
      setEffect,
      isCompanyAdmin
    };
  }

});