import { defineComponent, ref } from 'vue';
export default defineComponent({
  props: {
    setData: {
      type: Object
    },
    dayNum: {
      type: Number
    },
    dataKey: {
      type: Number
    },
    shiftData: {
      type: Array
    },
    cycleData: {
      type: Array
    }
  },
  emits: ['emptyTab', 'setTab', 'setTabCycle'],

  setup(props, context) {
    // console.log(props.setData)
    const visible = ref(false);
    const typesetType = ref('1'); //清空

    const handleEmpty = () => {
      context.emit('emptyTab', {
        dayNum: props.dayNum,
        dataKey: props.dataKey
      });
      visible.value = false;
    };

    const handleSet = val => {
      context.emit('setTab', {
        dayNum: props.dayNum,
        dataKey: props.dataKey,
        val
      });
      visible.value = false;
    };

    const handleSetCycle = val => {
      context.emit('setTabCycle', {
        dayNum: props.dayNum,
        dataKey: props.dataKey,
        val
      });
      visible.value = false;
    };

    return {
      visible,
      typesetType,
      handleEmpty,
      handleSet,
      handleSetCycle
    };
  }

});