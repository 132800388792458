import "ant-design-vue/es/modal/style/css";
import _Modal from "ant-design-vue/es/modal";
import { defineComponent, ref, createVNode } from 'vue';
import typeset from './components/typeset';
import shiftSet from './components/shiftSet';
import attendanceGroupSet from './components/attendanceGroupSet';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
export default defineComponent({
  components: {
    typeset,
    shiftSet,
    attendanceGroupSet
  },

  setup() {
    const activeKey = ref('1');
    const tabPane = ref();

    const handleTabChange = val => {
      const oldActive = activeKey.value;

      if (oldActive === '3' && val !== '3') {
        if (tabPane.value.oldData !== JSON.stringify(tabPane.value.data)) {
          return _Modal.confirm({
            title: '当前页存在未保存信息，确认放弃更改吗?',
            icon: createVNode(ExclamationCircleOutlined),

            onOk() {
              activeKey.value = val;
              tabPane.value.data = JSON.parse(tabPane.value.oldData);
            },

            onCancel() {},

            class: 'test'
          });
        }
      }

      activeKey.value = val;
    };

    return {
      activeKey,
      handleTabChange,
      tabPane
    };
  }

});