import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import "ant-design-vue/es/modal/style/css";
import _Modal from "ant-design-vue/es/modal";
import { defineComponent, ref, onMounted, reactive, createVNode, computed, onBeforeUnmount } from 'vue';
import moment from 'moment';
import { selectSchedulePeriod, selectClassOrders, insertSchedulePeriod, updateSchedulePeriod, selectSchedulingList, insertOrUpdateScheduling, selectSchedulePeriodByBelongProject } from '@/api/attendance';
import typesetSetPopover from './typesetSetPopover';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { onBeforeRouteLeave } from "vue-router";
import { useStore } from 'vuex';
const cycleColumns = [{
  title: '周期名称',
  dataIndex: 'periodName'
}, {
  title: '周期天数',
  dataIndex: 'periodNum'
}, {
  title: '周期班次',
  dataIndex: 'periodOrderStr'
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
export default defineComponent({
  components: {
    typesetSetPopover,
    ExclamationCircleOutlined
  },

  setup() {
    let columns = ref([]);
    let dayNum = ref(30);

    const getTableHeader = month => {
      columns.value = [{
        title: '考勤组',
        dataIndex: 'groupName',
        fixed: 'left',
        width: '100px'
      }];
      sessionsColumns.value = [{
        title: '班次',
        dataIndex: 'classOrderName',
        fixed: 'left',
        width: '100px'
      }];
      let arr = month.split('-');

      switch (arr[1]) {
        case '01':
        case '03':
        case '05':
        case '07':
        case '08':
        case '10':
        case '12':
          dayNum.value = 31;
          break;

        case '04':
        case '06':
        case '09':
        case '11':
          dayNum.value = 30;
          break;

        case '02':
          dayNum.value = 28;
          break;
      }

      let weekObj = {
        1: '一',
        2: '二',
        3: '三',
        4: '四',
        5: '五',
        6: '六',
        7: '日'
      };

      for (let i = 1; i <= dayNum.value; i++) {
        let str = weekObj[moment(`${month}-${i}`).isoWeekday()];
        columns.value.push({
          title: `${i}${str}`,
          dataIndex: i,
          slots: {
            customRender: i
          },
          width: '80px'
        });
        sessionsColumns.value.push({
          title: `${i}${str}`,
          dataIndex: i,
          slots: {
            customRender: i
          },
          width: '80px'
        });
      } // return columns

    };

    const data = ref([]);

    const handleMonth = month => {
      if (oldData.value !== JSON.stringify(data.value)) {
        _Modal.confirm({
          title: '当前页存在未保存信息，确认放弃更改吗?',
          icon: createVNode(ExclamationCircleOutlined),

          onOk() {
            getTableHeader(month);
            getTypographyData();
          },

          // onCancel() {
          //   console.log('取消');
          // },
          class: 'test'
        });
      } else {
        getTableHeader(month);
        getTypographyData();
      }
    };

    const value2 = ref(moment().startOf('day').format('YYYY-MM'));

    const emptyTab = obj => {
      console.log(obj);
      data.value[obj.dataKey][obj.dayNum].classOrderName = null;
    };

    const setTab = obj => {
      console.log(obj);
      data.value[obj.dataKey][obj.dayNum].classOrderName = obj.val.classOrderName;
      data.value[obj.dataKey][obj.dayNum].checkTime = obj.val.checkTime;
    };

    const setTabCycle = obj => {
      console.log(obj, obj.val.periodOrderStr);
      let arr = obj.val.classList;
      let num = 0;

      for (let i = obj.dayNum; i <= dayNum.value; i++) {
        if (num === arr.length) {
          num = 0;
        }

        data.value[obj.dataKey][i].classOrderName = arr[num].classOrderName;
        data.value[obj.dataKey][i].checkTime = arr[num].checkTime;
        num++;
      }
    }; //周期


    const cycleloading = ref(false);
    const cycleData = ref([]);

    const getTableData = async () => {
      cycleloading.value = true;

      try {
        let res = await selectSchedulePeriod();

        if (res.code === 200) {
          const arr = [];
          cycleData.value = [];
          if (res.data) res.data.forEach(item => {
            arr.push({ ...item,
              key: item.id
            });
          });
          Object.assign(cycleData.value, arr);
          cycleloading.value = false;
        } else {
          cycleloading.value = false;

          _message.error(res.message);
        }
      } catch {
        cycleloading.value = false;
      }
    }; //新建周期


    const newSetVisible = ref(false);
    const newSetTitle = ref('新建排班周期');
    const formState = reactive({
      periodName: "",
      periodNum: 1,
      periodOrder: null,
      periodOrderArr: []
    });
    const rules = {
      periodName: [{
        required: true,
        message: '周期名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }]
    };

    const handleNew = (val, num) => {
      if (num === 0) {
        formState.periodName = "";
        formState.periodNum = 1;
        formState.periodOrder = null;
        formState.periodOrderArr = [];
        newSetTitle.value = '新建排班周期';
        newSetVisible.value = true;
        getAllPeriodOrderData();
      } else {
        newSetTitle.value = '编辑排班周期';
        getAllPeriodOrderData(); // console.log(val)

        formState.periodName = val.periodName;
        formState.periodNum = val.periodNum;
        formState.periodOrderArr = [];
        formState.id = val.key;
        let arr = val.periodOrder.split(',');
        arr.forEach(item => {
          formState.periodOrderArr.push(Number(item));
        });
        newSetVisible.value = true;
      }
    };

    const formRef = ref();

    const numberEcho = e => {
      if (!e) {
        formState.periodNum = 1;
      }
    };

    const allPeriodOrder = ref([]);

    const getAllPeriodOrderData = async () => {
      let res = await selectClassOrders();

      if (res.code === 200) {
        const arr = [];
        allPeriodOrder.value = [];
        if (res.data) res.data.forEach(item => {
          arr.push({
            value: item.id,
            label: item.checkTime ? `${item.classOrderName} ${item.checkTime}` : `${item.classOrderName}`,
            classOrderName: item.classOrderName
          });
        });
        Object.assign(allPeriodOrder.value, arr);
      } else {
        cycleloading.value = false;

        _message.error(res.message);
      }
    };

    const periodLoading = ref(false);

    const onSubmit = () => {
      console.log('values', formState);
      formRef.value.validate().then(async () => {
        periodLoading.value = true; // console.log(11111111)

        let obj = {};
        Object.assign(obj, formState);
        obj.periodOrder = obj.periodOrderArr.toString(); // console.log(obj)

        let res = await insertSchedulePeriod(obj);

        if (res.code === 200) {
          _message.success(res.message);

          newSetVisible.value = false;
          getTableData();
          getCycleSetData();
        } else {
          _message.error(res.message);

          periodLoading.value = false;
        }

        periodLoading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    };

    const onEditor = () => {
      console.log('values', formState);
      formRef.value.validate().then(async () => {
        periodLoading.value = true; // console.log(11111111)

        let obj = {};
        Object.assign(obj, formState);
        obj.periodOrder = obj.periodOrderArr.toString(); // console.log(obj)

        let res = await updateSchedulePeriod(obj);

        if (res.code === 200) {
          _message.success(res.message);

          newSetVisible.value = false;
          getTableData();
          getCycleSetData();
        } else {
          _message.error(res.message);

          periodLoading.value = false;
        }

        periodLoading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    };

    let oldData = ref(null);
    const typographyLoading = ref(false);

    const getTypographyData = async () => {
      const param = {
        scheduleTime: value2.value
      };
      typographyLoading.value = true;
      let res = await selectSchedulingList(param);

      if (res.code === 200) {
        const arr = [];
        data.value = [];
        if (res.data) res.data.forEach(item => {
          let schedulePlanArr = {};
          let schedulePlan = JSON.parse(item.schedulePlan);

          if (schedulePlan) {
            schedulePlan.forEach((itx, index) => {
              schedulePlanArr[index + 1] = {
                classOrderName: itx.classOrderName,
                checkTime: itx.classOrderName,
                date: itx.date,
                isOperate: itx.isOperate === 'true' ? true : false
              };
            });
          }

          arr.push({
            key: item.groupId,
            groupName: item.groupName,
            scheduleTime: item.scheduleTime,
            ...schedulePlanArr
          });
        });
        console.log(arr);
        Object.assign(data.value, arr);
        oldData.value = JSON.stringify(arr);
      } else {
        _message.error(res.message);

        typographyLoading.value = false;
      }

      typographyLoading.value = false;
    };

    const setTypographyLoading = ref(false);

    const handleSeData = async () => {
      let arr = [];
      data.value.forEach(item => {
        let schedulePlan = [];

        for (let i = 1; i <= dayNum.value; i++) {
          schedulePlan.push(item[i]);
        }

        arr.push({
          groupId: item.key,
          scheduleTime: item.scheduleTime,
          groupName: item.groupName,
          schedulePlan: JSON.stringify(schedulePlan)
        });
      }); // console.log('arr',arr)

      let res = await insertOrUpdateScheduling(arr);

      if (res.code === 200) {
        _message.success(res.message);

        getTableHeader(value2.value);
        getTypographyData();
        setTypographyLoading.value = false;
      } else {
        _message.error(res.message);

        setTypographyLoading.value = false;
      }
    };

    const isShowBtn = computed(() => {
      if (oldData.value !== JSON.stringify(data.value)) {
        return true;
      } else {
        return false;
      }
    });

    const handleRestor = () => {
      data.value = JSON.parse(oldData.value);
    };

    const sessionsVisible = ref(false);
    const sessionsColumns = ref([]);
    const sessionsData = ref([]);

    const handleSession = () => {
      let arr = [];
      getAllPeriodOrderData().then(() => {
        allPeriodOrder.value.forEach(item => {
          let obj = {};

          for (let i = 1; i <= dayNum.value; i++) {
            obj[i] = 0;
          }

          arr.push({
            classOrderName: item.classOrderName,
            ...obj
          });
        });

        for (let s = 1; s <= dayNum.value; s++) {
          data.value.forEach(item => {
            arr.forEach((ixt, inx) => {
              if (item[s].classOrderName === ixt.classOrderName) {
                arr[inx][s]++;
              }
            });
          });
        } // console.log('arr',arr)


        Object.assign(sessionsData.value, arr);
        sessionsVisible.value = true;
      });
    }; //获取周期用于配置


    const cycleSetData = ref([]);

    const getCycleSetData = async () => {
      let res = await selectSchedulePeriodByBelongProject();

      if (res.code === 200) {
        cycleSetData.value = [];
        Object.assign(cycleSetData.value, res.data);
      } else {
        cycleloading.value = false;

        _message.error(res.message);
      }
    };

    const store = useStore();
    const isCompanyAdmin = computed(() => store.state.user.isCompanyAdmin);
    onMounted(() => {
      getTableHeader(value2.value);
      getTableData();
      getTypographyData();
      getAllPeriodOrderData();
      getCycleSetData();
    });
    onBeforeRouteLeave((to, from, next) => {
      if (oldData.value !== JSON.stringify(data.value)) {
        _Modal.confirm({
          title: '当前页存在未保存信息，确认放弃更改吗?',
          icon: createVNode(ExclamationCircleOutlined),

          onOk() {
            next();
          },

          onCancel() {
            next(false);
          },

          class: 'test'
        });
      } else {
        next();
      }
    });
    return {
      value2,
      data,
      columns,
      handleMonth,
      dayNum,
      emptyTab,
      cycleColumns,
      cycleloading,
      cycleData,
      newSetVisible,
      newSetTitle,
      formState,
      rules,
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 14
      },
      formRef,
      handleNew,
      numberEcho,
      allPeriodOrder,
      onSubmit,
      periodLoading,
      setTab,
      onEditor,
      typographyLoading,
      setTabCycle,
      handleSeData,
      setTypographyLoading,
      isShowBtn,
      handleRestor,
      sessionsVisible,
      handleSession,
      sessionsColumns,
      sessionsData,
      cycleSetData,
      oldData,
      isCompanyAdmin
    };
  }

});